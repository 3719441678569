import { createAction, props } from '@ngrx/store';
import { TokenDto } from '../../shared/models/franchise/franchise.model';

export const initUser = createAction('[User] Init User');

export const authenticateUser = createAction(
  '[User] Authenticate User',
  props<TokenDto>()
);

export const unauthenticateUser = createAction('[User] Unauthenticate User');

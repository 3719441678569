import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { throwError, Observable, of, EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  ClinicGroupFranchiseDto,
  LoginDto,
  PortalType,
  StackDto,
  TokenDto,
} from '../shared/models/franchise/franchise.model';
import { UrlConfig } from '../url.config';

import { HelpersService } from '../shared/utils/helpers/helpers.service';
import {
  authenticateUser,
  initUser,
  unauthenticateUser,
} from '../store/user/user.actions';
import { selectUser } from '../store/user/user.selectors';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private tokenExpirationTimer: any;

  qa1WebUrl = 'dev10-co.clinicmaster.com';

  constructor(
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private helper: HelpersService,
    private store: Store<any>
  ) {}

  public login(username: string, password: string) {
    return this.loginStaff(username, password).pipe(
      catchError(this.handleError),
      tap((resData) => {
        if (resData) this.handleAuthentication(resData);
      })
    );
  }

  autoLogin(): Observable<TokenDto | null> {
    this.store.dispatch(initUser());
    return this.store.select(selectUser).pipe(
      switchMap((user) => {
        if (this.helper.userIsAuthenticated(user)) {
          this.autoLogout(this.helper.getExpirationDuration(user));
          return EMPTY; // no need to return anything
        }
        return of({}); // return empty object to continue subscription
      })
    );
  }

  logout() {
    this.clearTimer();
    this.store.dispatch(unauthenticateUser());
    this.router.navigate(['/auth']);
  }

  clearTimer() {
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.clearTimer();
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  private handleAuthentication(tokenDto: TokenDto) {
    this.store.dispatch(authenticateUser(tokenDto));
    this.autoLogout(this.helper.getExpirationDuration(tokenDto));
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (errorRes.error) {
      errorMessage = errorRes.error;
    }
    return throwError(errorMessage);
  }

  private getDomainName(): string {
    const domains = location.hostname.split('.');
    if (domains[0] === '127') {
      return this.qa1WebUrl;
    }
    if (domains && domains.length > 1) {
      if (domains[0] === 'dev10') {
        return this.qa1WebUrl;
      }
      return window.location.host;
    } else {
      return this.qa1WebUrl;
    }
  }
  public getFranchise(): Observable<{
    domainInfo: StackDto;
    franchise: ClinicGroupFranchiseDto | null;
  }> {
    return this.getDomainInfo().pipe(
      take(1),
      switchMap((domainInfo: StackDto | null) => {
        if (domainInfo && domainInfo.clinicGroupFranchiseId) {
          return this.getClinicGroupFranchise(
            domainInfo.clinicGroupFranchiseId
          ).pipe(
            map((franchise: ClinicGroupFranchiseDto | null) => {
              return { domainInfo: domainInfo as StackDto, franchise };
            })
          );
        } else {
          return of({ domainInfo: domainInfo as StackDto, franchise: null });
        }
      })
    );
  }

  private getDomainInfo(): Observable<StackDto | null> {
    let subDomain = this.getDomainName();
    let params = new HttpParams();
    params = params.append('portalUrl', subDomain);
    const url = `${UrlConfig.coBaseUrl}centraloffice/token/portalUrl`;
    return this.http
      .get<StackDto>(url, { observe: 'response', params })
      .pipe(map((response) => response.body));
  }
  getClinicGroupFranchise(
    franchiseId: number | string
  ): Observable<ClinicGroupFranchiseDto | null> {
    const url = `${UrlConfig.coBaseUrl}clinicgroupfranchises/${franchiseId}`;
    return this.http
      .get<ClinicGroupFranchiseDto>(url, { observe: 'response' })
      .pipe(map((response) => response.body));
  }

  private loginStaff(
    username: string,
    password: string
  ): Observable<TokenDto | null> {
    const deviceInfo = this.deviceService.getDeviceInfo();

    const userAgent =
      (deviceInfo?.browser || '') + ' ' + (deviceInfo?.browser_version || '');
    const platform =
      (deviceInfo?.os || '') + ' ' + (deviceInfo?.os_version || '');

    const loginData = new LoginDto(
      username,
      password,
      PortalType.Staff,
      userAgent,
      platform
    );
    const url = `${UrlConfig.coBaseUrl}centraloffice/token/login`;
    return this.http.post<TokenDto>(url, loginData);
  }
}

export class StackDto {
  clinicGroupFranchiseId?: number;
  monitoringWebApiBaseUri?: string;
  monitoringApiKey?: string;
  signalRwebApiBaseUri?: string;
  chatApiUrl?: string;
  chatSocketUrl?: string;
  fileApiUrl?: string;
  formioProjectUrl?: string;
  telehealthUrl?: string;
  clinicMasterSettingsUrl?: string;
  customHelpUrl?: string;
  addatechHelpUrl?: string;
  reportApiUrl?: string;
  statusPageUrl?: string;
  ssoapiUrl?: string;
  centralOfficeApiUrl?: string;
  addaGraphApiUrl?: string;
  dispatcherApiUrl?: string;
  spellCheckApiUrl?: string;
  externalClientTicketUrl?: string;
  hcaiIntegrationApiUrl?: string;
  cnesstIntegrationApiUrl?: string;
  pacificBlueCrossIntegrationApiUrl?: string;
  telusIntegrationApiUrl?: string;
  cloverBaseUrl?: string;
  teleplanIntegrationApiUrl?: string;
  powerBiBaseUrl?: string;
  ohipApiUrl?: string;
  addatechCrmclientPortalUrl?: string;
  providerConnectApiUrl?: string;
}

export class ClinicGroupFranchiseDto {
  clinicGroupFranchiseId!: number;
  clinicGroupFranchiseUniqueId?: string;
  name?: string;
  clientFileIdAutoGenerateOnClientCreation?: boolean;
  clientFileIdPrefix?: string;
  clientFileIdNextNumber?: number | null;
  turnOffChartNotePostBusinessDatabaseUpdate?: boolean;
  enableTwoFactorAuthentication?: boolean;
  isClientProfileDataVisibleAcrossClinics?: boolean;
  useOwnClientPortal?: boolean;
  allowBackDatedInvoiceAndPaymentInPostedPeriod?: boolean;
  staffPortalUrl?: string;
  clientPortalUrl?: string;
  isClientPortalSso?: boolean;
  isActive?: boolean;
  ssoInfo?: ClinicGroupFranchiseSsoinfoDto[];
  clinicMasterPartnerId?: number | null;
  clinicGroups?: ClinicGroupDto[];
  clinicGroupFranchiseBrandingLogos?: ClinicGroupFranchiseBrandingLogoDto[];
  otherSupportedLanguages?: number[];
  termsAndConditionsSetup?: TermsAndConditionsSetupDto;
  clinicGroupFranchiseOnlineBookingSetup?: ClinicGroupFranchiseOnlineBookingSetupDto;
  stackInfo?: StackDto;
}
export class ClinicGroupFranchiseSsoinfoDto {
  clinicGroupFranchiseId?: number;
  sysSsotypeId?: number;
  clientId?: string;
  providerUri?: string;
  tenantId?: string;
  apiClientId?: string;
  returnUrl?: string;
  authSchema?: string;
}

export interface ClinicGroupDto {
  clinicGroupId: number;
  name: string;
  clinics: ClinicDto[];
}

export interface ClinicGroupFranchiseBrandingLogoDto {
  sysLogoDimensionId: number;
  logoFileUri: string;
  logoCloudinaryId: string;
}

export class TermsAndConditionsSetupDto {
  id?: number;
  clinicGroupFranchiseId?: number;
  termAndConditionFileUri?: string;
  termAndConditionFileCloudinaryId?: string;
  showWhenClientSignUp?: boolean;
  showOnClientFirstLogin?: boolean;
  validFrom?: string; // DateOnly is not available in TypeScript, use string or Date instead
  validTo?: string; // DateOnly is not available in TypeScript, use string or Date instead
  isActive?: boolean;
  createDateUtc?: Date;
  createUserId?: number;
  lastModDateUtc?: Date | null;
  lastModUserId?: number | null;
}

export class ClinicGroupFranchiseOnlineBookingSetupDto {
  clinicGroupFranchiseId?: number;
  logoFileUri?: string;
  backgroundImageFileUri?: string;
  primaryColor?: number;
  secondaryColor?: number;
  logoFilePath?: string;
  backgroundImageFilePath?: string;
}
export interface ClinicGroupFranchiseForTokenDto {
  isClientProfileDataVisibleAcrossClinics: boolean;
  enableTwoFactorAuthentication: boolean;
  allowBackDatedInvoiceAndPaymentInPostedPeriod: boolean;
}
export class TokenDto {
  token?: string;
  isRegister?: boolean;
  expires?: string;
  userId?: number;
  entityId?: number | null;
  //chatInfo: ChatInfoDto;
  //clinicGroupFranchise?: ClinicGroupFranchiseForTokenDto;
  clinicGroups?: ClinicGroupDto[];
  securityRoleIds?: number[];
  visibleMenuIds?: number[];
  enabledActionsIds?: number[];
  securityLevel?: number | null;
  coClinicId?: number | null;
  coClinicGroupId?: number | null;
  sourceStackId?: number | null;
  coClinicName?: string;
}

export enum PortalType {
  Client,
  Staff,
}
export class LoginDto {
  constructor(
    public userName: string,
    public password: string,
    public portalType: PortalType,
    public userAgent: string,
    public platform: string
  ) {}
}

export interface SubRegionDto {
  name: string;
  nameDe: string | null;
  nameEn: string;
  nameEs: string | null;
  nameFr: string | null;
  regionId: number;
  subRegionId: number;
}

export interface ClinicDto {
  clinicGroupId: number;
  clinicId: number;
  clinicName: string;
  goLiveDate: string;
  subRegion: SubRegionDto | null;
  subRegionId: number | null;
  chartingStartMonitoringMissingOn: string | null;
}

export interface DistributedClinicDto {
  clinicId: number;
  clinicName: string;
  disciplineId: number;
  disciplineName: string;
  subRegionId: number | null;
  subRegionName: string | null;
}

export enum LogoDimension {
  x72x72 = 1,
  x96x96 = 2,
  x128x128 = 3,
  x144x144 = 4,
  x152x152 = 5,
  x192x192 = 6,
  x384x384 = 7,
  x512x512 = 8,
}
